<template>
  <div class=" container-layout-custom justify-center full-height ak-text light-theme-text-default" style="max-width: 1980px;">
    <div class="q-pa-xl full-width">
      <div class="row q-mb-xl">
        <div v-if="mode === null" class="text-h4 col-md-9">Manage Categories</div>
        <div v-else class="text-h4 col-md-9">{{ mode }} Category</div>
      </div>

      <div v-if="mode === null" class="row q-gutter-xl">
        <q-btn @click="setAction('Create')" color="primary">Add New Category</q-btn>
        <q-btn @click="showCategoryBrowse = true" color="primary">Edit Existing Category</q-btn>
      </div>

      <div v-else>
        <q-form @submit="mode === 'Create' ? createCategory() : updateCategory()" @reset="setAction(null)" autocomplete="off">
          <div v-if="mode === 'Create' || category.uid" class="row q-mt-xl">
            <div class="col-4">
              <div class="text-h6 q-mt-sm text-right q-mr-lg">Basic Information</div>
            </div>
            <q-card flat class="col-8 bg-lmsBackground">
              <q-card-section class="row">
                <q-input class="col-12" filled label="Category Name" v-model.trim="category.name" :rules="formRules.title" />
              </q-card-section>
              <q-card-section class="row justify-between">
                <q-checkbox v-model="category.settings.overlayTitle" label="Overlay Title"/>
                <q-checkbox v-model="category.hasParentCategory" label="Has Parent Category"/>
              </q-card-section>
              <q-card-section class="row items-center" v-if="category.hasParentCategory">
                <div v-if="category.parent_uid" class="text-subtitle1 q-mx-xl">Current Parent Category: {{ $store.getters['categories/getByUid'](this.categories, category.parent_uid).name }}</div>
                <q-btn color="primary" :label="`${category.hasParentCategory && category.parent_uid ? 'Change' : 'Add'} Parent`" @click="showGetParentCategoryBrowse = true" />
              </q-card-section>
              <q-card-section class="row items-center justify-between">
                <q-file class="col-8" filled v-model="categoryImage" accept="image/*" label="Category Image (Actual Size: 250x140)" />
                <div class="col-3">
                  <q-btn color="primary" outline label="Choose From Library" @click="showMediaLibrary = true" />
                </div>
              </q-card-section>
              <q-card-section class="row">
                <div class="col-12 center" id="createTopLevelCropperContainer">
                  <img ref="cropperImage" id="createTopLevelCropper" src="" />
                </div>
                <div v-if="showCropButton" class="row full-width justify-center q-mt-lg">
                  <q-btn color="primary" label="Crop" @click="cropImageToBlob" />
                </div>
              </q-card-section>
            </q-card>
          </div>
          <div v-if="(mode === 'Create' || category.uid) && imageIsCropped" class="row q-mt-xl">
            <div class="col-4">
              <div class="text-h6 q-mt-sm text-right q-mr-lg">Category Preview</div>
            </div>
            <q-card flat class="col-8 bg-lmsBackground">
              <q-card style="min-width: 250px; max-width: 250px">
                <q-img :src="imagePreview">
                  <!-- styling with oswald -->
                  <div v-if="category.settings.overlayTitle" class="absolute-full category-skim text-center text-weight-medium flex flex-center">
                    <div v-if="category.name" class="font-oswald" :style="`font-size: ${getFontSize(category.name)}`">{{ category.name }}</div>
                  </div>
                </q-img>
              </q-card>
            </q-card>
          </div>
          <div class="row q-mt-xl">
            <div class="col-4"></div>
            <q-card flat class="col-8 bg-lmsBackground">
              <q-card-section v-if="mode === 'Create' || category.uid" class="row q-my-lg q-gutter-md">
                <q-btn class="col-3" type="submit" color="primary" :disable="!category.name || !imageIsCropped">Submit</q-btn>
                <q-btn flat type="reset">Cancel</q-btn>
              </q-card-section>
              <q-card-section v-else class="row q-my-lg justify-end">
                <q-btn color="primary" label="Save Order" type="reset" />
                <q-btn flat type="reset">Cancel</q-btn>
              </q-card-section>
              <q-card-section v-if="mode === 'Edit' && category.uid" class="row justify-center">
                <q-btn flat @click="deleteCategory">Delete Category</q-btn>
              </q-card-section>
            </q-card>
          </div>
          <div v-if="category.children && category.children.length > 0" class="row q-mt-xl">
            <div class="col-4">
              <div class="text-h6 q-mt-sm text-right q-mr-lg">Rearrange {{ category.uid ? 'Sub' : 'Main' }} Categories</div>
            </div>
            <q-card flat class="col-8 bg-lmsBackground">
              <q-card-section>
                <q-list>
                  <draggable handle=".drag-handle" v-model="category.children" @end="updateDisplayOrder(category.children)">
                    <q-item v-for="subCat in category.children" :key="subCat.uid">
                      <q-item-section avatar class="drag-handle cursor-pointer">
                        <q-icon name="drag_indicator"/>
                      </q-item-section>
                      <q-card class="drag-handle cursor-pointer" style="min-width: 250px; max-width: 250px">
                        <q-img :src="subCat.thumbnail_path">
                          <!-- styling with oswald -->
                          <div v-if="subCat.settings.overlayTitle" class="absolute-full category-skim text-center text-weight-medium flex flex-center">
                            <div v-if="subCat.name" class="font-oswald" :style="`font-size: ${getFontSize(subCat.name)}`">{{ subCat.name }}</div>
                          </div>
                        </q-img>
                      </q-card>
                    </q-item>
                  </draggable>
                </q-list>
              </q-card-section>
            </q-card>
          </div>
        </q-form>
      </div>

      <q-dialog v-model="showMediaLibrary" full-height>
        <StoredImageSelect @imageSelected="selectStoredMedia" />
      </q-dialog>

      <q-dialog v-model="showCategoryBrowse" full-width full-height>
        <CategoryBrowse @selectedCategory="editCategory" @closeModal="showCategoryBrowse = false" allowSelectAll allowRearrangeOption />
      </q-dialog>

      <q-dialog v-model="showGetParentCategoryBrowse" full-width full-height>
        <CategoryBrowse @selectedCategory="category.parent_uid = $event.uid; showGetParentCategoryBrowse = false;" @closeModal="showGetParentCategoryBrowse = false" allowSelectAll :excludeCategoryUid="category.uid" removeIfHasModules />
      </q-dialog>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import Cropper from 'cropperjs'
// import CategorySelect from 'components/CategorySelect'
import CategoryBrowse from 'components/CategoryBrowse'
import StoredImageSelect from 'components/StoredImageSelect'

export default {
  data () {
    return {
      rearrangeTopCategories: false,
      showCropButton: false,
      imageIsCropped: false,
      imagePreview: null,
      mode: null,
      categoryImage: null,
      croppedCategoryImage: null,
      showCategoryForm: false,
      showCategoryBrowse: false,
      showMediaLibrary: false,
      showGetParentCategoryBrowse: false,
      category: {
        name: null,
        hasParentCategory: false,
        settings: {
          overlayTitle: false
        },
        children: []
      },

      cropper: null,
      cropperTopLevel: false,
      categories: []
    }
  },
  components: { draggable, CategoryBrowse, StoredImageSelect },
  computed: {
    formRules: function () {
      return {
        title: [ this.$formRules.required('Title') ]
      }
    }
  },
  watch: {
    '$route.path': function () {
      switch (this.$route.name) {
        case 'manageCategoriesNew':
          this.setAction('Create')
          break
        case 'manageCategoriesEdit':
          this.setAction('Edit')
          break
        default:
          this.setAction(null)
      }
    },
    categoryImage (newVal) {
      if (newVal) {
        this.showCropButton = true
        this.createCropper(newVal)
      }
    }
  },
  methods: {
    selectStoredMedia (image) {
      this.showMediaLibrary = false
      this.createCropper(image)
      this.showCropButton = true
    },
    cancelForm () {
      Object.assign(this.$data, this.$options.data.apply(this))
    },
    updateDisplayOrder (categories) {
      let cats = categories.map((cat, i) => {
        cat.display_order = i + 1
        return cat
      })
      this.$store.dispatch('categories/order', cats).then((categories) => {
        this.$successMsg('Category Order has been updated')
      })
    },
    cropImageToBlob () {
      let blobLink = this.cropperTopLevel.getCroppedCanvas()
      let isIE = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent) && !!(document.uniqueID || document.documentMode || window.ActiveXObject || window.MSInputMethodContext)
      if (isIE) {
        this.croppedCategoryImage = blobLink.msToBlob()
        this.imageIsCropped = true
        this.showImagePreview()
        this.showCropButton = false
      } else {
        blobLink.toBlob((blobLink) => {
          this.croppedCategoryImage = blobLink
          this.showImagePreview()
          this.showCropButton = false
        }, 'image/jpeg', 1)
      }
    },
    showImagePreview () {
      let reader = new FileReader()
      reader.readAsDataURL(this.croppedCategoryImage)
      reader.onloadend = () => {
        this.imagePreview = reader.result
        this.imageIsCropped = true
        document.getElementById('createTopLevelCropper').src = ''
        document.getElementById('createTopLevelCropperContainer').style.height = '0px'
        this.cropperTopLevel.destroy()
      }
    },
    async setAction (which) {
      this.mode = which

      switch (this.mode) {
        case 'Create':
          this.$router.push({ name: 'manageCategoriesNew' }).catch(err => err)
          break
        case 'Edit':
          if (this.category.uid) {
            this.$router.push({ name: 'manageCategoriesEdit' }).catch(err => err)
          } else {
            this.$router.push({ name: 'manageSiteCategories' }).catch(err => err)
          }
          break
        default:
          this.cancelForm()
          this.$router.push({ name: 'manageSiteCategories' }).catch(err => err)
          this.categories = await this.$store.dispatch('categories/fetch', 'edit')
      }
    },
    editCategory (category) {
      this.showCategoryBrowse = false
      if (category.uid) {
        category.hasParentCategory = category.parent_uid !== null
        this.category = { ...category }
      } else {
        this.category.children = this.categories
      }
      this.setAction('Edit')
      this.imageIsCropped = true
      this.imagePreview = category.thumbnail_path
    },
    async updateCategory () {
      this.$loading()

      let fd = new FormData()
      fd.append('name', this.category.name)
      fd.append('settings', JSON.stringify(this.category.settings))
      fd.append('display_order', this.category.display_order) // TODO: This needs to be fixed so it starts creating the right display order for modules.
      if (this.category.parent_uid) {
        fd.append('category_uid', this.category.parent_uid)
      }
      if (this.categoryImage) {
        fd.append('file', this.croppedCategoryImage)
      }

      try {
        await this.$axios.post(this.$consts.UPDATE_CATEGORY_URL + '/' + this.category.uid, fd, { headers: { 'Content-Type': 'multipart/form-data' } })
        this.setAction(null)
        this.$loading(false)
        this.$successMsg('Category has been updated')
      } catch (e) {
        this.$loading(false)
        this.$failureMsg()
      }
    },
    deleteCategory () {
      if ((this.category.children && this.category.children.length > 0) || this.category.hasModules) {
        this.$q.notify({
          color: 'negative',
          icon: 'block',
          position: 'bottom-left',
          message: 'Cannot delete a category with sub categories or modules still attached to it'
        })
        return
      }
      this.$q.dialog({
        title: 'Warning',
        message: 'You are sure you want to delete this category?',
        color: 'primary',
        ok: true,
        cancel: true
      }).onOk(async () => {
        this.$loading()

        try {
          await this.$axios.delete(this.$consts.DELETE_CATEGORY_URL + '/' + this.category.uid)
          this.setAction(null)
          this.cancelForm()
          this.$loading(false)
          this.$successMsg('Category has been deleted')
        } catch (e) {
          this.$loading(false)
          this.$failureMsg()
        }
      })
    },
    createCropper (tFile) {
      if (tFile.path) {
        document.querySelector('#createTopLevelCropper').src = tFile.path
      } else {
        document.querySelector('#createTopLevelCropper').src = (window.URL || window.webkitURL).createObjectURL(tFile)
      }
      document.querySelector('#createTopLevelCropperContainer').style.height = (window.outerHeight * 0.4) + 'px'
      if (!this.$refs.cropperImage) {
        return
      }
      if (this.cropperTopLevel) {
        this.cropperTopLevel.destroy()
        this.cropperTopLevel = false
      }
      let cropper = new Cropper(this.$refs.cropperImage, {
        aspectRatio: 16 / 9,
        viewMode: 2,
        responsive: true,
        autoCropArea: 1,
        dragMode: 'move'
      })
      this.cropperTopLevel = cropper
    },
    async createCategory () {
      this.$loading()
      let fd = new FormData()
      fd.append('name', this.category.name)
      fd.append('file', this.croppedCategoryImage)
      fd.append('settings', JSON.stringify(this.category.settings))
      fd.append('display_order', 0) // TODO: This needs to be fixed so it starts creating the right display order for modules.
      if (this.category.parent_uid) {
        fd.append('category_uid', this.category.parent_uid)
      }

      try {
        await this.$axios.post(this.$consts.ADD_CATEGORY_URL, fd, { headers: { 'Content-Type': 'multipart/form-data' } })
        this.setAction(null)
        this.$loading(false)
        this.$successMsg('Category has been added')
      } catch (e) {
        this.$loading(false)
        this.$failureMsg()
      }
    },
    getFontSize (name) {
      if (name.length <= 6) {
        return '56px'
      } else if (name.length <= 10) {
        return '48px'
      } else if (name.length <= 14) {
        return '40px'
      } else if (name.length <= 18) {
        return '32px'
      } else if (name.length <= 22) {
        return '28px'
      } else if (name.length <= 26) {
        return '24px'
      } else if (name.length <= 30) {
        return '20px'
      } else {
        return '16px'
      }
    }
  },
  async created () {
    this.categories = await this.$store.dispatch('categories/fetch', 'edit')

    if (this.$route.name === 'manageCategoriesNew') {
      this.setAction('Create')
    }

    let cropperCSS = document.createElement('link')
    cropperCSS.setAttribute('href', 'https://cdn.jsdelivr.net/npm/cropperjs/dist/cropper.css')
    cropperCSS.setAttribute('rel', 'stylesheet')
    document.head.appendChild(cropperCSS)
  }
}
</script>

<style lang="stylus">
.font-oswald
  font-family 'Oswald', sans-serif
.category-skim
  background rgba(0,0,0,0.57) !important
</style>
